import LS from '../../assets/js/ls';
import store from '../../store';
const menu = {
  getMenu:function () {
    let menuList=[]
    let defaultList=this.defaultMenu()
    if(store.getters.roleList&&store.getters.roleList.length>0){
      let arr= store.getters.roleList.map(item=>item.Name)
      for(let item of defaultList){
        let temp = []
        for(let sub of item.items){
          if(arr.indexOf(sub.title)>-1){
            temp.push(sub)
          }
        }
        if(arr.indexOf(item.title)>-1){
          item.items = temp
          menuList.push(item)
        }
      }
    }
    // menuList.forEach(item=>{
    //   if(item.title=="助手管理"){
    //     item.items.push({ sid: 4, title: "企业成员", index: "4-2", url: "/companymember"})
    //   }
    // })
    return menuList
  },
  defaultMenu:function(){
   const menuList = [
      {
        id: 1, title: "群发工具", icon: "el-icon-c-scale-to-original", index: "1",
        items: [
            { sid: 1, title: "群聊发送", index: "1-1", url: "/groupsend"},  
            { sid: 1, title: "单聊发送", index: "1-2", url: "/singlesend"},
            { sid: 1, title: "群公告", index: "1-3", url: "/grouppublish/list"},      
            { sid: 1, title: "企业标签", index: "1-4", url: "/syncgrouplist"}, 
        ]
      },
      {
        id: 2, title: "群管理", icon: "el-icon-video-camera-solid", index: "2",
        items: [
            { sid: 2, title: "群列表", index: "2-1", url: "/groupdetail"},
            { sid: 2, title: "开通新群", index: "2-2", url: "/groupnew"},
            { sid: 2, title: "群标签", index: "2-3", url: "/grouptag"},
            { sid: 2, title: "群聊邀请设置", index: "2-4", url: "/groupsetting"},
            { sid: 2, title: "助手入群", index: "2-5", url: "/groupadd"},
            { sid: 2, title: "自动回复", index: "2-6", url: "/groupautowrap"},
            { sid: 2, title: "防骚扰规则", index: "2-7", url: "/harassrule"},
            { sid: 2, title: "入群欢迎语", index: "2-8", url: "/groupwelcome"},
        ]
      },
      // {
      //   id: 3, title: "好友管理", icon: "el-icon-video-camera-solid", index: "3",
      //   items: [
      //       { sid: 3, title: "批量加好友", index: "3-1", url: "/groupsend"},
      //   ]
      // },
      {
        id: 4, title: "助手管理", icon: "el-icon-user-solid", index: "4",
        items: [
          { sid: 4, title: "助手列表", index: "4-1", url: "/robotpage"},
          { sid: 4, title: "企业成员", index: "4-2", url: "/companymember"}
        ]
      },
      {
        id: 5, title: "素材管理", icon: "el-icon-picture", index: "5",
        items: [
          { sid: 5, title: "素材库", index: "5-1", url: "/materiallib"},  
          // { sid: 5, title: "更新日志", index: "5-2", url: "/minibook"},
          { sid: 5, title: "素材来路", index: "5-3", url: "/miniprogram"},
        ]
      },
      // {
      //   id: 6, title: "助手会话工具", icon: "el-icon-video-camera-solid", index: "6",
      //   url:"/groupsend"
        
      // },
      {
        id: 7, title: "集合聊天", icon: "el-icon-chat-dot-round", index: "7",
        items: [
          { sid: 7, title: "聊天", index: "7-1", url: "/chatmain"}
        ]
      },
      {
        id: 8, title: "系统管理", icon: "el-icon-s-tools", index: "8",
        items: [
          { sid: 8, title: "账号管理", index: "8-1", url: "/accountmanager"},
          { sid: 8, title: "权限管理", index: "8-2", url: "/powermanager"},
          { sid: 8, title: "白名单用户", index: "8-3", url: "/whitelist"},
          { sid: 8, title: "迭代日志", index: "8-4", url: "/dailylist"},
          { sid: 8, title: "操作日志", index: "8-5", url: "/actionloglist"}
        ]
      }
    ];
    return menuList
  }
}

export default menu