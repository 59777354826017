<template>
  <div class="home">
    <el-container style="height: 100vh; border: 1px solid #eee">
      <!-- 菜单开始 -->
      <el-aside width="16%" class="page-el-aside">
        <div class="logo-title">
          <img src="../../assets/imgs/logo_h_black.png" alt="" />
        </div>
        <el-menu
          :default-openeds="['0','1','2','3','4','5','6','7','8','9']"
          :default-active="currentMenu"
          style="border-right: none"
          background-color="#000"
          text-color="#fff"
          active-text-color="#F7D164"
        >
          <router-link
            v-for="item in menuList"
            :key="item.index"
            :to="item.url ? item.url : ''"
          >
            <el-submenu :index="item.index">
              <template #title>
                <i :class="item.icon"></i>
                <span class="top-menu">{{ item.title }}</span>
              </template>
              <router-link
                v-for="seconditem in item.items"
                :key="seconditem.index"
                :to="seconditem.url"
                :target="seconditem.title=='聊天'?'_blank':''"
              >
                <el-menu-item :index="seconditem.index" @click="openTag(seconditem)">
                  {{ seconditem.title }}
                </el-menu-item>
              </router-link>
            </el-submenu>
          </router-link>
        </el-menu>
      </el-aside>
      <!-- 菜单结束 -->
      <el-container>
        <!-- 页头开始 -->
        <el-header class="p-el-header">
          <div
            class="header-icon"
            :class="{ pointer: isBack }"
            @click="backPreAction"
          >
            <el-icon :size="25" :color="'#000'" v-if="isBack">
              <ArrowLeft />
            </el-icon>

            <span class="page-title">{{ pageTitle }}</span>
          </div>

          <div class="CenterField" v-if="pageTitle == '群公告'">
            <div
              :class="theme == 0 ? 'select' : 'normal'"
              @click="changeTheme(0)"
            >
              当前公告
            </div>
            <div
              :class="theme == 1 ? 'select' : 'normal'"
              @click="changeTheme(1)"
            >
              发布记录
            </div>
          </div>
          <div class="CenterField" v-if="pageTitle == '自动回复'">
            <div :class="type == 0 ? 'select' : 'normal'" style="width:120px" @click="changeAuto(0)">群聊自动回复</div>
            <div :class="type == 1 ? 'select' : 'normal'" style="width:120px" @click="changeAuto(1)">1对1自动回复</div>
          </div>
          <div class="user-div">
            <img src="../../assets/imgs/default1.png" class="button-style2" />

            <el-dropdown size="medium" trigger="click" placement="bottom" style="text-align:left">
              <div style="cursor: pointer">
                <span style="font-size: 14px">{{userName}}</span>
                <div class="version">{{version}}</div>
              </div>

              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="detailAction"><el-icon><DocumentCopy /></el-icon>版本历史</el-dropdown-item>
                  <el-dropdown-item @click="logoutAction"><i class="el-icon-switch-button"></i>退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
         
          </div>
        </el-header>
        <!-- 页头结束 -->

        <!-- 这里是主体部分 -->
        <el-main class="page-main">
          <div>
            <router-view />
          </div>
        </el-main>
      </el-container>
    </el-container>
    <DailyDetailCop ref="dailyDetailRef"/>
  </div>
</template>

<script>
import {
  ref,
  onMounted,
  computed,
  getCurrentInstance,
  watch,
  watchEffect,
} from "vue";
import menu from "@/assets/js/menu";
import { useRoute, useRouter } from "vue-router";
import LS from "@/assets/js/ls";
import { useStore } from "vuex";
import { ElMessageBox, ElMessage } from "element-plus";
import { ArrowLeft,ArrowDown,SwitchButton,DocumentCopy} from "@element-plus/icons";
import {SiteGetAllCategory} from "@/helper/setting"
import {Logout} from '@/helper/user'
import DailyDetailCop from "@/components/SettingManager/DailyDetailCop"
import store from '../../store';
import {GetUserInfo } from "@/helper/brand.js";

export default {
  name: "Menu",
  components: {
    ArrowLeft,ArrowDown,SwitchButton,DailyDetailCop,DocumentCopy
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const store = useStore();
    const theme = computed(() => store.getters.publishInfo.theme);
    const userName = computed(() => store.getters.userInfo.LoginName);
    const version=computed(() => store.getters.version)
    const type = computed(() => store.getters.autoReplay.type);

    const data = {};
    const currentMenu = ref("1-1"); //菜单序号
    const menuList = ref([]);
    const dailyDetailRef=ref(null)
    

    //监听页面标题
    const pageTitle = ref(""); //页面标题
    const isBack = ref(false);
    watchEffect(() => {
      pageTitle.value = proxy.$route.meta.title;
      currentMenu.value = proxy.$route.meta.index;
      isBack.value = !proxy.$route.meta.noBack || false;
      let data = {
        PagePath: proxy.$route.path,
        PageName: proxy.$route.meta.title,
      };
    });

    onMounted(() => {
      SiteGetAllCategory().then(res=>{
        menuList.value = menu.getMenu();
      })
      // menuList.value=menu.defaultMenu();
      currentMenu.value = proxy.$route.meta.index;
      isBack.value = !proxy.$route.meta.noBack;
    });
    


    //返回
    function backPreAction() {
      if (isBack.value) {
        router.go(-1);
      }
    }

    /********************点击事件**********************/
    function detailAction(){
      dailyDetailRef.value.initCop()
    }

    function openTag(item){
      if(item.title=='聊天'){
        let routeUrl = router.resolve({
          path: item.url
        });
        window.open(routeUrl.href, '_blank');
      } else {
        router.push({path:item.url})
      }
      
    }

    //退出
    function logoutAction() {
      ElMessageBox.confirm("是否确认登出", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          LS.clear("userInfo"); //退出的时候清空用户个人信息缓存
          Logout().then(()=>{
            router.replace("/");
            ElMessage({
              type: "success",
              message: "登出成功!",
            });
          })
          
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "已取消",
          });
        });
    }

    //切换主题
    function changeTheme(index) {
      store.commit("setPublishInfo", index);
    }

    //切换
    function changeAuto(index) {
      store.commit("setAutoReplay", index);
    }

    return {
      currentMenu,
      pageTitle,
      menuList,
      logoutAction,
      theme,
      changeTheme,
      type,
      isBack,
      backPreAction,
      userName,
      openTag,
      version,
      detailAction,
      dailyDetailRef,
      changeAuto
    };
  },
};
</script>
<style lang="scss" scoped>
.logo-title {
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  color: #bfb28b;
  font-size: 18px;
  font-weight: bold;

  img {
    width: 60%;
    // height: 30px;
    margin-right: 10px;
  }
}
.page-el-aside {
  background-color: #000;
  color: #fff;
  padding-bottom:20px;
  .el-menu {
    background-color: #000;
  }
  .el-submenu{
    width: 100%;
  }
  :deep().el-submenu .el-menu::before{
    display:none !important;
    content:none !important;
  }
  :deep().el-submenu .el-menu{
    display: none !important;
  }
  :deep().is-opened .el-menu{
    display: grid !important;
    grid-template-columns: repeat(auto-fill, 40%) !important;
    // justify-content: space-around !important;
    padding-left: calc(7% + 29px) !important;
  }

  .el-menu-item {
    // width: 120px;
    padding: 0px !important;
    margin: 10px 0px;
    font-size: 13px;
    min-width: 0px !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 30px;
    width: 30%;
  }
  .el-menu-item.is-active {
    // background-color: #BEAF88 !important;
    span {
      color: #f7d164 !important;
    }
  }
  .el-submenu.el-icon-arrow-down:before {
    content: "" !important;
  }
}

:deep().el-submenu__title{
  padding-left: 7% !important;
}

.top-menu {
  font-size: 14px;
  display: inline-block;
  width: 0px;
  text-align: left;
  font-weight: 600;
  color: #d7d7d7;
}
.p-el-header {
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: right;
  font-size: 12px;
  background: #fff;
  border-bottom: 1px solid #eee;
  .page-title {
    // border-left: 4px solid #409EFF;
    font-weight: 700;
    // text-indent: 0.5em;
    font-size: 20px;
    color: #333;
  }
}
.page-main {
  background: #eee;
  padding: 10px;
}
.page-main>div{
  height: 100%;
  background: #fff;
}
.el-menu-item.is-active {
  background: #409eff;
  color: #fff;
}
.user-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.button-style2 {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.CenterField {
  height: 30px;
  font-size: 14px;
  color: #000;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  cursor: pointer;
  .normal {
    background-color: #fff;
    color: $color-common;
    width: 80px;
    border: 1px solid $color-common;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .select {
    background-color: $color-common;
    width: 80px;
    border: none;
    color: #fff;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.header-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

:deep()body, ul, li, input, img, a, p, h1, h2, h3, h4, h5, h6, dl, dt, dd, textarea{
  width: 100%;
}
.version{
  font-size: 14px;
  text-align: left;
  cursor: pointer;
  margin-top: 5px;
}
</style>