<template>
  <div class="container">
    <el-dialog title="系统迭代日志" width="1000px" v-model="dialogVisible">
      <div class="cell-wrap">
        <ul class="left">
          <li
            v-for="(item, index) in tableData"
            :key="index"
            @click="selectMenu(index)"
            :class="{ active: index == activeStep }"
          >
            {{ item.Version }}
          </li>
        </ul>
        <ul class="right" ref="right" @scroll="onScroll">
          <li
            v-for="(item, index) in tableData"
            :key="item + 1"
            class="scrollContent"
            :id="'rightLi' + index"
          >
            <div class="header">
              <h3>{{ item.Version }}</h3>
              <span v-if="item.IsRelease == 1">当前版本</span>
            </div>
            <div class="time" v-if="item.ReleaseTime">发布时间：{{ item.ReleaseTime }}</div>
            <div class="content">
              <p v-html="item.Content"></p>
            </div>
          </li>
        </ul>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, ref, unref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { ChangeLogList } from "@/helper/setting";
import Tool from "@/assets/js/tools";


export default {
  name: "DailyDetailCop",
  components: {},
  setup() {
    const dialogVisible = ref(false);
    const tableData = ref([]);
    const activeStep = ref(0);
    const right = ref(null);

    function initCop() {
      dialogVisible.value = true;
      loadData();
    }

    function loadData() {
      let param = {
        PageIndex: 1,
        PageSize: 99999,
      };
      ChangeLogList(param).then((res) => {
        console.log(res);
        tableData.value = res.List || [];
        tableData.value.forEach((item)=>{
          item.Content=item.Content.replace(/\n/g,'<br>')
        })
      });
    }

    function selectMenu(index) {
      activeStep.value = index;
      document.getElementById(`rightLi${index}`).scrollIntoView()
    }

    function onScroll(e) {
      let scrollItems = document.querySelectorAll('.scrollContent');
      for (let i = scrollItems.length - 1; i >= 0; i--) {
        // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
        let judge =e.target.scrollTop >= scrollItems[i].offsetTop - scrollItems[0].offsetTop;
        if (judge) {
          activeStep.value = i;
          break;
        }
      }
    }
    return {
      initCop,
      dialogVisible,
      tableData,
      activeStep,
      selectMenu,
      loadData,
      right,
      onScroll,
    };
  },
};
</script>
<style lang='scss' scoped>
.cell-wrap {
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}
.left {
  width: 16%;
  height: 486px;
  overflow: auto;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  color: #000;
  border-right: 1px solid #ccc;
  margin-right: 10px;
  cursor: pointer;
  li {
    padding: 10px 0;
  }
}
.right {
  width: 90%;
  height: 486px;
  overflow: auto;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  color: #000;
  margin-left: 30px;
  li {
    padding: 10px 0;
    .header {
      display: flex;
      flex-direction: row;
      align-content: center;
      span {
        margin-left: 10px;
        border-radius: 5px;
        font-size: 12px;
        padding: 0 10px;
        color: #fff;
        background: $color-common;
      }
    }
    .time {
      margin-top: 8px;
      font-size: 12px;
      color: #ccc;
    }
    .content {
      padding: 20px 0;
      font-size: 13px;
    }
  }
}
.active {
  color: $color-common;
}
</style>