import $axios from '../assets/js/request.js'
import crypto from '../assets/js/crypto.js'
let baseURL = process.env.VUE_APP_URL

//当前站点所有栏目
export function SiteGetAllCategory(data) {
    return $axios.post(`${baseURL}/Site/GetAllCategory`,data);
}
//获得当前品牌下所有角色
export function RoleGetAllRole(data) {
    return $axios.post(`${baseURL}/Role/GetAllRole`,data);
}
//获得当前品牌下所有子账号
export function AccountGetList(data) {
    return $axios.post(`${baseURL}/Account/GetList`,data);
}
//创建角色
export function RoleCreate(data) {
    return $axios.post(`${baseURL}/Role/Create`,data);
}
//修改角色
export function RoleUpdate(data) {
    return $axios.post(`${baseURL}/Role/Update`,data);
}
//删除角色
export function RoleDelete(data) {
    return $axios.post(`${baseURL}/Role/Delete`,data);
}
//（当前品牌）创建子账号
export function AccountCreate(data) {
    return $axios.post(`${baseURL}/Account/Create`,data);
}
//（当前品牌）修改子账号
export function AccountUpdate(data) {
    return $axios.post(`${baseURL}/Account/Update`,data);
}
//（当前品牌）禁用子账号
export function AccountDisable(data) {
    return $axios.post(`${baseURL}/Account/Disable`,data);
}
//（当前品牌）获取子账号信息
export function AccountInfo(data) {
    return $axios.post(`${baseURL}/Account/Info`,data);
}

//迭代日志列表
export function ChangeLogList(data) {
    return $axios.post(`${baseURL}/ChangeLog/List`,data);
}
//增加迭代日志
export function ChangeLogCreate(data) {
    return $axios.post(`${baseURL}/ChangeLog/Create`,data);
}
//删除迭代日志
export function ChangeLogDisable(data) {
    return $axios.post(`${baseURL}/ChangeLog/Disable`,data);
}
//修改迭代日志
export function ChangeLogUpdate(data) {
    return $axios.post(`${baseURL}/ChangeLog/Update`,data);
}
//迭代日志详情
export function ChangeLogInfo(data) {
    return $axios.post(`${baseURL}/ChangeLog/Info`,data);
}

//模块页面功能列表
export function OperationLogSelectList(data) {
    return $axios.post(`${baseURL}/OperationLog/SelectList`,data);
}

//（当前品牌）获得所有账号
export function AccountSimpleList(data) {
    return $axios.post(`${baseURL}/Account/SimpleList`,data);
}

//操作日志列表
export function OperationLogList(data) {
    return $axios.post(`${baseURL}/OperationLog/List`,data);
}