import $axios from '../assets/js/request.js'
import crypto from '../assets/js/crypto.js'
let baseURL = process.env.VUE_APP_URL

//品牌列表
export function GetBrandList(data) {
    return $axios.post(`${baseURL}/Brand/GetList`,data);
}

//新增品牌
export function CreateBrand(data) {
    return $axios.post(`${baseURL}/Brand/Create`,data);
}



//修改品牌
export function UpdateBrand(data) {
    return $axios.post(`${baseURL}/Brand/Update`,data);
}

//品牌用户列表
export function GetUserList(data) {
    return $axios.post(`${baseURL}/User/List`,data);
}

//启用或禁用用户
export function UserStatus(data) {
    return $axios.post(`${baseURL}/User/Status`,data);
}

//增加用户
export function UserAdd(data) {
    return $axios.post(`${baseURL}/User/Add`,data);
}

//编辑用户
export function UserUpdate(data) {
    return $axios.post(`${baseURL}/User/Update`,data);
}


//品牌配置列表
export function GetBrandConfig(data) {
    return $axios.post(`${baseURL}/Brand/GetConfig`,data);
}


//新增和修改品牌配置
export function SetBrandConfig(data) {
    return $axios.post(`${baseURL}/Brand/SetConfig`,data);
}

//禁用及启用相关配置项
export function SetConfigStatus(data) {
    return $axios.post(`${baseURL}/Brand/ConfigStatus`,data);
}

//获取品牌基础信息
export function GetBrandInfo(data) {
    return $axios.post(`${baseURL}/Brand/GetInfo`,data);
}
//获取品牌用户信息
export function GetUserInfo(data) {
    return $axios.post(`${baseURL}/User/Info`,data);
}

